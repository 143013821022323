import styled from 'styled-components';

export const RootContainer = styled.div`
	max-width: 100vw;
	max-height: 100vh;
	display: flex;
`;

export const VideoContainer = styled.div`
	max-height: 100vh;
	flex: 3;
	display: flex;
	justify-content: center;
	background-color: #333;
`;

export const QuestionContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const QuestionCard = styled.div`
	padding: 10px;
	width: calc(100% - 20px);

	&:nth-child(even) {
		background-color: #eee;
	}
`;

export const QuestionTitle = styled.div`
	font-size: 16px;
	font-weight: bold;	
`;

export const QuestionTime = styled.div`
	font-size: 14px;
	font-weight: normal;	
`;