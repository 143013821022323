import { Provider } from "react-redux";
// import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";
import theme from "./Theme";
import RootRouter from "./navigation";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import createStore from "./store";
import apiDefaults from "utils/apiDefaults";

const { store, persistor } = createStore();
apiDefaults();

function App() {
  return (
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
        <ThemeProvider theme={theme}>
          <RootRouter />
          <ToastContainer position="top-center" newestOnTop={true} />
        </ThemeProvider>
      {/* </PersistGate> */}
    </Provider>
  );
}

export default App;
