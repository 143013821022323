import styled from 'styled-components'

export const RootContainer = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
`;

export const LogoImage = styled.img`
	height: 60px;
	width: auto;
`;

export const BodyContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const WelcomeText = styled.div`
	font-size: 1.5rem;
	line-height: 1.5rem;
	color: #333;
`;

export const MainText = styled.h2`
	margin: 0px;
	color: #333;
`;

export const TaglineText = styled.div`
	font-size: 1.5rem;
	line-height: 2.25rem;
	color: #333;
`;

export const StartButton = styled.div`
	padding: 10px 20px;
	background-color: ${({theme}) => theme.colors.blue};
	color: ${({theme}) => theme.colors.white};
`;

export const NoteText = styled.p`
	color: ${({danger}) => danger?"#f00":"#333"};
`;

export const ArrowImage = styled.img`
	height: 100px;
	width: 60px;
	opacity: 0.7;
`;