import {asyncActionGenerator, plainActionGenerator} from 'utils/actionCreator';

export const getUserAsyncActions = asyncActionGenerator('get_user');

export const getAllAnswersAsyncActions = asyncActionGenerator('get_all_answers');

export const saveUserAsyncActions = asyncActionGenerator('save_user');

export const updateAnswerAction = plainActionGenerator('update_answer');

export const getDashboardDataAsyncActions = asyncActionGenerator('get_dashoard_data_async_actions');

export const saveAnswerAsyncActions = asyncActionGenerator('save_answer');

export const savePreferencesAction = plainActionGenerator('save_Preference');

export const saveLinkedInAnswerAsyncActions = asyncActionGenerator('save_linkedin_answer');

