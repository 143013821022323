import {
	getUserAsyncActions,
	saveUserAsyncActions,
	getAllAnswersAsyncActions,
	saveAnswerAsyncActions,
	updateAnswerAction,
	savePreferencesAction,
	saveLinkedInAnswerAsyncActions,
} from './actions';

const initialState = {
	isLoading: false,
	answers: [],
	profile: {
		name: '',
		email: ''
	},
	words: '',
	type: '',
	accountId: '',
	allAnswers: [],
}

export default (state = initialState, action) => {
	switch(action.type) {
		case getUserAsyncActions.actionNames.request:
			return {...state, isLoading: true, accountId: action.payload.data, words: action.payload.words, type: action.payload.type}

		case getUserAsyncActions.actionNames.success:
			return {...state, profile: action.payload, isLoading: false}

		case getUserAsyncActions.actionNames.error:
			return {...state, profile: action.payload, isLoading: false}

		case getAllAnswersAsyncActions.actionNames.request:
			return {...state, isLoading: true,}

		case getAllAnswersAsyncActions.actionNames.success:
			return {...state, allAnswers: action.payload, isLoading: false}

		case getAllAnswersAsyncActions.actionNames.error:
			return {...state, allAnswers: action.payload, isLoading: false}

		case updateAnswerAction.actionName:
			return {...state, answers: [...state.answers, action.payload], isLoading: false}

		case savePreferencesAction.actionName: {
			// console.log("Action Payload", action.type, action.payload)
			return {...state, words: action.payload.words, type: action.payload.type}
		}

		case saveUserAsyncActions.actionNames.request:
			return {...state, isLoading: true}

		case saveUserAsyncActions.actionNames.success:
			return {...state, profile: action.payload, isLoading: false}

		case saveUserAsyncActions.actionNames.error:
			return {...state, profile: action.payload, isLoading: false}

		case saveLinkedInAnswerAsyncActions.actionNames.request:
			return {...state, isLoading: true}

		case saveLinkedInAnswerAsyncActions.actionNames.success:
			return {...state,  isLoading: false}

		case saveLinkedInAnswerAsyncActions.actionNames.error:
			return {...state, profile: action.payload, isLoading: false}

		case saveAnswerAsyncActions.actionNames.request:
			return {...state, isLoading: true}

		case saveAnswerAsyncActions.actionNames.success:
			return {...state, isLoading: false}

		case saveAnswerAsyncActions.actionNames.error:
			return {...state, isLoading: false}

		default:
			return {...state}
	}
}