import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Routes
} from "react-router-dom";
import {
	Instructions,
	Interview,
	Opening,
	Recording,
	PreviousRecording,
	PostTest,
	Thanks,
	Dashboard,
} from 'screens';

const RootRouter = () => {
	return (
		<Router>
			<Routes>
				<Route path='/instructions' element={<Instructions />} />
				<Route path='/interview' element={<Interview />} />
				<Route path='/postpitch/' element={<PostTest />} />
				<Route path='/previousRecording/:id' element={<PreviousRecording />} />
				<Route path='/thanks/' element={<Thanks />} />
				<Route path='/recording/:id' element={<Recording />} />
				<Route path='/dashboard' element={<Dashboard />} />
				<Route path='/' element={<Opening />} />
			</Routes>
		</Router>
	)
}

export default RootRouter;