import {useState, useEffect} from 'react';
import {
	RootContainer,
	LogoImage,
	BodyContainer,
	MainText,
	TaglineText,
	GifImage,
} from './Thanks.styles';
import Logo from 'assets/images/brainsprays-logo.webp';
// import GIF from 'assets/images/leonardo-dicaprio.gif';
import {PrimaryButton, VerticalSpace} from 'components';
import ConfettiExplosion from '@reonomy/react-confetti-explosion';
import {useParams, useNavigate} from 'react-router-dom';

const Thanks = () => {
	const [showConfetti, setShowConfetti] = useState(true)
	const navigateToDashboard = useNavigate();

	useEffect(() => {
		setTimeout(() => setShowConfetti(false), 3000)
	}, [])

	return (
		<RootContainer>
			{showConfetti && <ConfettiExplosion duration={5000} force={0.6} particleCount={200} floorHeight={1600} floorWidth={1600} />}
			<VerticalSpace count={1} />
			<LogoImage src={Logo} />
			<BodyContainer>
				<MainText>Congrats, All done! 🎉</MainText>
				<VerticalSpace count={2} />
				<TaglineText>An automated assessment generated by Artificial Intelligence (AI) will be reviewed by a real person and compared to your estimate.  A digital transcript of your child’s work and progress today will be available to help you plan their future lessons.</TaglineText>
				<VerticalSpace count={3} />
				{/*<GifImage src={GIF} />*/}
				<GifImage loading="lazy" src="https://media.giphy.com/media/IW4mHapH6yv66DuOzl/giphy.gif" alt="success" />
				<VerticalSpace count={3} />
				<PrimaryButton onClick={() => navigateToDashboard('/dashboard')} >Go to dashboard</PrimaryButton>				
			</BodyContainer>
		</RootContainer>
	)
}

export default Thanks;