import {useState, useEffect} from 'react';
import {
  RootContainer,
  LogoImage,
  BodyContainer,
  WelcomeText,
  MainText,
  TaglineText,
  ArrowImage,
  StartButton,
  NoteText,
  TextInput,
} from './PostTest.styles';
import StarRatingComponent from "react-star-rating-component";
import Logo from 'assets/images/brainsprays-logo.webp';
import {PrimaryButton, VerticalSpace, Modal} from 'components';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import { useAuth0 } from "@auth0/auth0-react";
import validate from 'validate.js';
import {useNavigate} from 'react-router-dom';
import {Duration, DateTime} from 'luxon';
import useQuery from 'utils/useQuery';
import {userActions} from 'modules/user';

export const constraints = {
  email: {
    presence: { 
    	allowEmpty: false,
    	message: '^You cannot leave this field empty'
    },
    email: {
    	message: '^This doesn\'t look like a valid email for us. Please re-check' 
    },
  },
  landingPage: {
    presence: { 
    	allowEmpty: false,
    	message: '^You cannot leave this field empty' 
    },
    // format: {
    //   pattern: "[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)?",
    //   message: "^This is not a valid URL. Please recheck",
    // },
  },
};

const PostTest = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const query = useQuery()
	const {profile, accountId, answers, action} = useSelector(state => state.user, shallowEqual)
	const {user, isAuthenticated, isLoading} = useAuth0();

	const [email, setEmail] = useState('');
	const [landingPage, setLandingPage] = useState('');
	const [ratings, setRatings] = useState(5);
	const [error, setError] = useState({});

	const onDone = () => {
		const errors = validate({email, landingPage}, constraints)
		if(errors) {
			// console.log("Error", errors, {email, landingPage})
			setError(errors)
			return;
		}
		const videoId = query.get('videoId')
		if(accountId) {
			dispatch(userActions.saveAnswerAsyncActions.request({data: {answeredBy: accountId, videoId, createdOn: DateTime.now().toISO(), landingPage, email, ratings: ratings}, callback: () => window.open('https://zoom.us/launch/chat?jid=robot_v1em0xtlwvts-bfb9xneacma@xmpp.zoom.us', '_self')}))
		}
		else {
			dispatch(userActions.saveLinkedInAnswerAsyncActions.request({data: {videoId, id: user?.sub, createdOn: DateTime.now().toISO(), landingPage, email, ratings}, callback: () => navigate('/thanks')}))
		}
	}

	useEffect(() => {
		setEmail(user?.email)
	}, [user])

	return (
		<RootContainer>
			<VerticalSpace count={1} />
			<LogoImage src={Logo} />
        	<BodyContainer>
        		<MainText>One last thing</MainText>
        		<VerticalSpace count={4} />
        		<TaglineText>What email address shall we send the assessment to?*</TaglineText>
        		<VerticalSpace count={1} />
        		<TextInput error={error.email} placeholder="Your Email*" value={email} onChange={evt => setEmail(evt.target.value)} />
        		<VerticalSpace count={3} />
        		<TaglineText>How many of the phonics assessment do you think your child got right?*</TaglineText>
        		<VerticalSpace count={1} />
        		<TextInput placeholder="You estimate (1 to 4)*" error={error.landingPage} value={landingPage} onChange={evt => setLandingPage(evt.target.value)} />
        		<VerticalSpace count={3} />
        		<TaglineText>How was your overall experience?</TaglineText>
        		<VerticalSpace count={1} />
        		{/*Increase star font size -https://github.com/voronianski/react-star-rating-component/blob/master/example/index.js*/}
        		<div style={{fontSize: 30}}>
        		<StarRatingComponent
        		  className="text-2xl -mt-1"
        		  name="review-star"
        		  starCount={5}
        		  value={ratings}
        		  onStarClick={(nextValue) => setRatings(nextValue)}
        		/>
        		</div>
        		<VerticalSpace count={3} />
        		<PrimaryButton onClick={onDone} >Done</PrimaryButton>
        	</BodyContainer>
		</RootContainer>
	)
}

export default PostTest;