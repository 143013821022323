import styled from 'styled-components';

export const RootContainer = styled.div`
	width: 100vw;
	min-height: 100vh;
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 25px;
`;

export const HeaderText = styled.div`
	font-size: 36px;
	color: #000;
	font-weight: bold;
`;

export const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const ContentWrapper = styled.div`
	padding: 25px;

	.react-multiple-carousel__arrow--left {
		left: 0;
	}

	.react-multiple-carousel__arrow--right {
		right: 0;
	}
`;

export const SectionTitle = styled.div`
	font-size: 28px;
	color: #000;
	font-weight: bold;
`;

export const LatestPitchContainer = styled.div`
	display: flex;
	align-items: flex-start;
`;

export const VideoContainer = styled.div`
	flex: 2;
	display: flex;
	justify-content: center;
	height: calc(100vh / 1.5);
	background-color: #333;
	overflow: hidden;
	background-color: #000;
`;

export const VideoDetailContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const VideoDetailTextContainer = styled.div`
	padding: 15px;
`;

export const VideoDetailText = styled.div`
	font-size: 18px;
	font-weight: bold;
	color: ${({type}) => type==="minor"?"#777":"#000"};
`;

export const QuestionContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const QuestionCard = styled.div`
	padding: 10px;
	width: calc(100% - 20px);

	&:nth-child(even) {
		background-color: #eee;
	}
`;

export const QuestionTitle = styled.div`
	font-size: 16px;
	font-weight: bold;
`;

export const QuestionTime = styled.div`
	font-size: 14px;
	font-weight: normal;	
`;

export const SmallVideoContainer = styled.div`
	position: relative;
	width: 300px;
	height: 200px;
	background: #232526;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #414345, #232526);  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	cursor: pointer;
	align-self: center;
	margin: auto;
`;

export const VideoThumbnailImage = styled.img`
	position: absolute;
	margin: auto;
	z-index: 50;
	top: 0;
	left: 0;
	width: 300px;
	height: 200px;
	object-fit: stretch;
`;

export const SmallVideoContainerTextWrapper = styled.div`
	padding: 5px 15px;
	background-color: rgba(0,0,0,0.7);
	z-index: 100;
`;

export const SmallVideoContainerText = styled(QuestionTitle)`
	color: #fff;
	font-weight: normal;
`;