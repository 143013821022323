import { useEffect, useState, useRef } from "react";
import {
  RootContainer,
  ParticipantWrapper,
  ParticipantContainer,
  ParticipantEmail,
  ParticipantName,
  TestWrapper,
  TestHeading,
  TestContainer,
  ButtonBar,
  ModalTitle,
  ModalContent,
  StartButton,
} from './Interview.styles';
import {useNavigate} from 'react-router-dom';
import {PrimaryButton, VerticalSpace, Modal} from 'components';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {userActions} from 'modules/user';
import {Duration, DateTime} from 'luxon';
import { useAuth0 } from "@auth0/auth0-react";
import { setup, isSupported } from "@loomhq/loom-sdk";
import { oembed } from "@loomhq/loom-embed";
import { Timer, Time, TimerOptions } from 'timer-node';
import {toast} from 'react-toastify';
import {animated, useTransition} from 'react-spring';
import SquareLoader from 'react-spinners/SquareLoader';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

  // const API_KEY = "28bb8238-2572-4a69-a2cc-a1262c0a7df5"; //dev
  const API_KEY = "5fd94efe-f454-4779-a372-bfc67b36a99b"; //prd
  const BUTTON_ID = "loom-sdk-button";

const Interview = () => {
  var timerInterval;
  var sdkButton;
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const buttonRef = useRef();
  const {profile, accountId, answers, words, type} = useSelector(state => state.user, shallowEqual)
  const [questions, setQuestions] = useState([])
  const [sharedUrl, setSharedUrl] = useState("");
  const [isTestStarted, setIsTestStarted] = useState(false)
  const [sdkButtonObject, setSdkButtonObject] = useState(null)
  const [showStopRecordingModal, setShowStopRecordingModal] = useState(false)
  const [currentTime, setCurrentTime] = useState(0);
  const [currentQuestionStart, setCurrentQuestionStart] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [currentLoomStatus, setCurrentLoomStatus] = useState('');
  const [showStartButton, setShowStartButton] = useState(false);
  const testTimer = new Timer({label: 'test-timer'})
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

  const questionSelector = () => {
    let questionsArray = []
    if(type === 'practice') {
      switch(words) {
        case 'at': {
          questionsArray = [
            {
              title: "AT words - Family Sing Along",
              link: "https://app.chapterme.co/trailerSelection/61c71f8cf402f1f4cfe0876d"
            },
            {
              title: "AT words - Sing In The Blanks",
              link: "https://app.chapterme.co/trailerSelection/61c720b4f402f1f4cfe0876f"
            },
          ]
          break;
        }
        case 'og': {
          questionsArray = [
            {
              title: "OG words - Family Sing Along",
              link: "https://app.chapterme.co/trailerSelection/61c7190fa55b9070aa239ef4"
            },
            {
              title: "OG words - Sing In The Blanks",
              link: "https://app.chapterme.co/trailerSelection/61c71936a55b9070aa239ef7"
            },
          ]
          break;
        }
        case 'ip': {
          questionsArray = [
            {
              title: "IP words - Family Sing Along",
              link: "https://app.chapterme.co/trailerSelection/61c7195ca55b9070aa239efa"
            },
            {
              title: "IP words - Sing In The Blanks",
              link: "https://app.chapterme.co/trailerSelection/61c71982a55b9070aa239efd"
            },
          ]
          break;
        }
        case 'id': {
          questionsArray = [
            {
              title: "ID words - Family Sing Along",
              link: "https://app.chapterme.co/trailerSelection/61c71a43a55b9070aa239f0c"
            },
            {
              title: "ID words - Sing In The Blanks",
              link: "https://app.chapterme.co/trailerSelection/61c71a67a55b9070aa239f0f"
            },
          ]
          break;
        }       
        case 'op': {
          questionsArray = [
            {
              title: "OP words - Family Sing Along",
              link: "https://app.chapterme.co/trailerSelection/61c719f4a55b9070aa239f06"
            },
            {
              title: "OP words - Sing In The Blanks",
              link: "https://app.chapterme.co/trailerSelection/61c71a18a55b9070aa239f09"
            },
          ]
          break;
        }
        case 'ot': {
          questionsArray = [
            {
              title: "OT words - Family Sing Along",
              link: "https://app.chapterme.co/trailerSelection/61c719a7a55b9070aa239f00"
            },
            {
              title: "OT words - Sing In The Blanks",
              link: "https://app.chapterme.co/trailerSelection/61c719cda55b9070aa239f03"
            },
          ]
          break;
        }
        default: {
          questionsArray = [
            {
              title: "AT words - Family Sing Along",
              link: "https://app.chapterme.co/trailerSelection/61c71f8cf402f1f4cfe0876d"
            },
            {
              title: "AT words - Sing In The Blanks",
              link: "https://app.chapterme.co/trailerSelection/61c720b4f402f1f4cfe0876f"
            },
          ]
          break;
        }
      }
    }
    else {
      switch(words) {
        case 'at': {
          questionsArray = [
            {
              title: "AT words - Sing In The Blanks",
              link: "https://app.chapterme.co/trailerSelection/61c720b4f402f1f4cfe0876f"
            },
          ]
          break;
        }
        case 'og': {
          questionsArray = [
            {
              title: "OG words - Sing In The Blanks",
              link: "https://app.chapterme.co/trailerSelection/61c71936a55b9070aa239ef7"
            },
          ]
          break;
        }
        case 'ip': {
          questionsArray = [
            {
              title: "IP words - Sing In The Blanks",
              link: "https://app.chapterme.co/trailerSelection/61c71982a55b9070aa239efd"
            },
          ]
          break;
        }
        case 'id': {
          questionsArray = [
            {
              title: "ID words - Sing In The Blanks",
              link: "https://app.chapterme.co/trailerSelection/61c71a67a55b9070aa239f0f"
            },
          ]
          break;
        }
        case 'op': {
          questionsArray = [
            {
              title: "OP words - Sing In The Blanks",
              link: "https://app.chapterme.co/trailerSelection/61c71a18a55b9070aa239f09"
            },
          ]
          break;
        }
        case 'ot': {
          questionsArray = [
            {
              title: "OT words - Sing In The Blanks",
              link: "https://app.chapterme.co/trailerSelection/61c719cda55b9070aa239f03"
            },
          ]
          break;
        }
        default: {
          questionsArray = [
            {
              title: "AT words - Sing In The Blanks",
              link: "https://app.chapterme.co/trailerSelection/61c720b4f402f1f4cfe0876f"
            },
          ]
          break;
        }
      }
    }
    setQuestions(questionsArray);
  }

  const screenTransitions = useTransition(isTestStarted, {
      from: { position: 'absolute', opacity: 0 },
      enter: { opacity: 1, width: '100%' },
      leave: { opacity: 0 },
  })

  const setCurrentTimerseconds = () => {
    timerInterval = setInterval(() => setCurrentTime(Math.floor(testTimer.ms()/1000)), 1000)
  }

  async function setupLoom() {
    try {
      const { supported, error } = await isSupported();

      if (!supported) {
        // console.log(`Error setting up Loom: ${error}`);
        return;
      }

      
      const button = document.getElementById(BUTTON_ID);

      if (!button) {
        return;
      }
      
      const SetupOutput = await setup({
        apiKey: API_KEY,
        config: {
          insertButtonText: "Save recording"
        }
      });


      sdkButton = SetupOutput.configureButton({ element: button });
      setSdkButtonObject(sdkButton)
      setShowStartButton(true)


      sdkButton.on("insert-click", video => {
        setSharedUrl(video.sharedUrl);
        onSubmitRecording(video)
      });

      sdkButton.on('recording-start', () => {
        setIsTestStarted(true)
        setCurrentTimerseconds()
         SpeechRecognition.startListening({ continuous: true });
        testTimer.start()
      });

      sdkButton.on('complete', () => {
        setIsTestStarted(true)
         SpeechRecognition.stopListening()
        testTimer.stop()
      });

      sdkButton.on('cancel', () => {
        setIsTestStarted(true)
        testTimer.clear()
        navigate('/interview')
      });

      sdkButton.on('lifecycle-update', state => {
        // console.log("LIFECYCLE UPDATE", state)
      });
    }
    catch(err) {
      // console.log("LOOM SDK ERROR", err)
      // toast.error("Something went wrong! You will be taken to Dashboard automatically.")
      setTimeout(() => navigate('/dashboard'), 3000);
    }
  }

  useEffect(() => {
    if(!words || !type) {
      navigate('/instructions')
    }

    questionSelector();
    setupLoom();

    window.addEventListener('popstate', (e) => {
      // window.location.reload()
      // navigate('/dashboard')
      window.open('/dashboard', '_self')
    })

    window.onbeforeunload = function() {
      return "Data will be lost if you leave the page, are you sure?";
    };

    return () => {
      window.removeEventListener('popstate', () => null)
    }
  }, [])

  useEffect(() => {
    if(!(accountId || isAuthenticated) && !isLoading) {
       navigate('/')
    }
  }, [accountId, isAuthenticated, isLoading])

  const onSubmitRecording = (video) => {
    setShowStopRecordingModal(false)
     SpeechRecognition.stopListening()
    if(accountId && !user)
      dispatch(userActions.saveAnswerAsyncActions.request({data: {answeredBy: accountId, videoId: video.id, createdOn: DateTime.now().toISO()}, callback: () => window.open('https://zoom.us/launch/chat?jid=robot_v1em0xtlwvts-bfb9xneacma@xmpp.zoom.us', '_self')}))
    else {
      setShowStopRecordingModal(false)
      SpeechRecognition.stopListening()
      navigate(`/postpitch?videoId=${video.id}`)
    }
  }

  const onTestComplete = () => {
    if(sdkButtonObject?.endRecording)
      sdkButtonObject?.endRecording()
    else
      setShowStopRecordingModal(true)
  }

  const onNextQuestion = () => {
    const answerText = transcript.toLowerCase().slice(0, -1).replace(questions[currentQuestion], '')
    // console.log("ANSWER", answerText, currentQuestion)
    const object = {questionNumber: currentQuestion+1, time: currentQuestionStart, question: questions[currentQuestion].title, answer: transcript?transcript:"Your answer here..."}
    dispatch(userActions.updateAnswerAction.action(object))
    resetTranscript()
    setCurrentQuestionStart(currentTime)
    
    if(currentQuestion < questions.length-1) {
      setCurrentQuestion(p => p+1)
      const parentNode = document.querySelector('#iframe-container');
      const oldIframe = document.querySelector('iframe');
      const newIframe = oldIframe.cloneNode();
      newIframe.src = questions[currentQuestion+1].link;
      parentNode.replaceChild(newIframe, oldIframe);
    }
    else {
      onTestComplete()
    }
  }

  // console.log("LISTENING", listening)
  // console.log("Gokka Magane", buttonRef.current)

  return (
    <RootContainer>
      <VerticalSpace count={2} />
      {
        isTestStarted
        ?
        <TestWrapper>
          {/*<TestHeading>Question {currentQuestion+1}: {questions[currentQuestion].title}</TestHeading>*/}
          <TestHeading>{questions[currentQuestion].title}</TestHeading>
          <TestContainer>
            <p id="iframe-container" align="center">
              <iframe src={questions[0].link} title="Question" height="100%" style={{minWidth: "750px", minHeight: "450px", background: "black", borderRadius: "30px"}} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" scrolling="no" loading="lazy" allowfullscreen></iframe>
            </p>
          </TestContainer>
          <ButtonBar>
            <PrimaryButton background="green" onClick={onNextQuestion} >{currentQuestion < questions.length-1?'Start Assessment →':'End Assessment'}</PrimaryButton>
          </ButtonBar>
          {
            listening
            ?
            <p align="center">Listening... (Please <strong>don't</strong> refresh the page 🔄 or go back ⬅. Your recording will not be saved!)</p>
            :
            null
          }          
        </TestWrapper>
        :
        <TestWrapper>
          <TestHeading>{profile.name || user?.name}, please read our recommendations below for best experience 👇</TestHeading>
          <TestContainer>
            When you click on start you will get to choose how you want to record (you need to give Loom - camera and microphone permissions).
            <br/>
            <br/>
            1. We recommend you select "Screen and Camera" and use computer speakers (no headphones 🚫🎧) so that we can record both video audio and your voice.
            <br/>
            2. You will get to preview the recording before saving. So don't worry!
            <br/>
            3. <strong>Please don't refresh the page or go back!</strong> Your recording will not be saved.
            <br/>
            <br/>
            Start when you are ready!
          </TestContainer>        
          <ButtonBar>
            {
              showStartButton
              ?
              null
              :
              <SquareLoader size={35} color="#138DCA" />
            }  
            <StartButton isVisible={showStartButton} id={BUTTON_ID} background="green" ref={buttonRef} >Start →</StartButton>
          </ButtonBar>
        </TestWrapper>
      }
      {
        showStopRecordingModal
        ?
        <Modal close={() => setShowStopRecordingModal(false)} >
          <ModalTitle>Press stop (🟥) to preview and save recording</ModalTitle>
          <ModalContent>
            Tip - If you can't find the stop button it's next to your video bubble below.
            <br/>
            <br/>
            ***********************************************************************
            <br/>            
            <b>Note</b> - Once you save, you will be redirected to our Phonics Zoom-bot. Click on "View recording" to watch your latest recording. Feel free to share it with others.
          </ModalContent>
        </Modal>
        :
        null
      }
{/*      {
        isLoading
        ?
        <Modal close={() => null} >
          <ModalTitle>Recording saved! Redirecting to our Phonics app in 3 seconds...</ModalTitle>
          <ModalContent>If signed in, this video will be saved to your loom account. Click on "View recording" to watch your latest recording. Feel free to share it with others.</ModalContent>
        </Modal>
        :
        null
      }*/}
    </RootContainer>
  );
}

export default Interview;
