import {useState, useEffect} from 'react';
import {PrimaryButton, VerticalSpace, HorizontalSpace} from 'components';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {userActions} from 'modules/user';
import { useAuth0 } from "@auth0/auth0-react";

import {
	RootContainer,
	ParticipantWrapper,
	ParticipantContainer,
	ParticipantEmail,
	ParticipantName,
	InstructionWrapper,
	InstructionsHeading,
	InstructionsContainer,
	ButtonBar,
	TestTypeContainer,
	TestTypeButton,
	WordTypeContainer,
	WordTypeButton
} from './Instructions.styles';

const Instructions = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { user, isAuthenticated, isLoading } = useAuth0();
	const [setupDialogState, setSetupDialogState] = useState(1);
	const [type, setType] = useState('practice');
	const [words, setWords] = useState('at');
	const {profile, accountId} = useSelector(state => state.user, shallowEqual)

	const onDone = () => {
		 dispatch(userActions.savePreferencesAction.action({type, words}))
		 navigate('/interview')
	}

	useEffect(() => {
	  // console.log("GOPPAN MAVANE", user, accountId, isAuthenticated, isLoading)
	  if(!(accountId || isAuthenticated) && !isLoading) {
	     navigate('/')
	  }
	}, [accountId, isAuthenticated, isLoading])

	return (
		<RootContainer>
			<ParticipantWrapper>
				<ParticipantContainer>
					<ParticipantName>
						Welcome, {profile.name || user?.name}
					</ParticipantName>
				</ParticipantContainer>
			</ParticipantWrapper>
			<VerticalSpace count={2} />
			<InstructionWrapper>
				<InstructionsHeading>{setupDialogState === 1?"What do you want to do today?":"Choose a phonics to get started 👇"}</InstructionsHeading>
				<InstructionsContainer>
				{
					setupDialogState === 1
					?
					<TestTypeContainer>
						<TestTypeButton selected={type === 'practice'} onClick={() => setType('practice')} >Practice & Assess</TestTypeButton>
						<TestTypeButton selected={type === 'test'} onClick={() => setType('test')} >Assess</TestTypeButton>
					</TestTypeContainer>
					:
					<WordTypeContainer>
						<WordTypeButton selected={words === "at"} onClick={() => setWords('at')} >AT Words</WordTypeButton>
						<WordTypeButton selected={words === "og"} onClick={() => setWords('og')} >OG Words</WordTypeButton>
						<WordTypeButton selected={words === "ip"} onClick={() => setWords('ip')} >IP Words</WordTypeButton>
						<WordTypeButton selected={words === "id"} onClick={() => setWords('id')} >ID Words</WordTypeButton>
						<WordTypeButton selected={words === "op"} onClick={() => setWords('op')} >OP Words</WordTypeButton>
						<WordTypeButton selected={words === "ot"} onClick={() => setWords('ot')} >OT Words</WordTypeButton>
					</WordTypeContainer>
				}
				</InstructionsContainer>
				<ButtonBar>
					{
						setupDialogState === 1
						?
						<PrimaryButton onClick={() => setSetupDialogState(2)} >Next →</PrimaryButton>
						:
						<PrimaryButton onClick={onDone} >Instructions →</PrimaryButton>
					}
				</ButtonBar>
			</InstructionWrapper>
		</RootContainer>
	)
}

export default Instructions;
