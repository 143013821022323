import styled from 'styled-components';

export const RootContainer = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
`;

export const LogoImage = styled.img`
	height: 60px;
	width: auto;
`;

export const BodyContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const WelcomeText = styled.div`
	font-size: 1rem;
	line-height: 1.5rem;
	color: #333;
`;

export const MainText = styled.h1`
	margin: 0px;
	color: #333;
`;

export const TaglineText = styled.div`
	font-size: 1.5rem;
	line-height: 2.25rem;
	color: #333;
`;

export const TextInput = styled.input.attrs({type: 'text'})`
	width: 50%;	
	border: none;
	outline: none;
	border-radius: 5px;
	border: 2px solid ${({theme, error}) => error?"#f00":"#ccc"};
	padding: 5px;
	// Increase height and text size - https://www.codegrepper.com/code-examples/html/increase+size+of+input+box+html
	height: 2%;
	font-size:20px;

	&:focus {
		border: 2px solid ${({theme, error}) => error?"#f00":theme.colors.blue};
	}
`;