import styled from 'styled-components';
import {PrimaryButton} from 'components';

export const RootContainer = styled.div`
	max-width: 100%;
	min-height: 100%;
`;

export const ParticipantWrapper = styled.div`
	display: flex;
	align-items: center;
	background-color: ${({theme}) => theme.colors.halfWhite};
	margin: 15px;
	width: calc(100% - 60px);
	padding: 15px;
`;

export const ParticipantContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`;

export const ParticipantEmail = styled.div`
	color: ${({theme}) => theme.colors.black};
	font-size: 14px;
`;

export const ParticipantName = styled(ParticipantEmail)`
	font-weight: bold;
	line-height: 22px;
`;

export const TestWrapper = styled(ParticipantWrapper)`
	display: block;
`;

export const TestHeading = styled.h2`
	text-align: center;
`;

export const TestContainer = styled.div`
	margin: 10px;
	padding: 10px;
	background-color: ${({theme}) => theme.colors.white};
	font-size:18px;
	// display: flex;
	// flex-direction: column;
	// justify-content: center;
`;

export const ButtonBar = styled.div`
	display: flex;
	padding: 15px;
	justify-content: center;
`;

export const ModalTitle = styled.div`
	margin: 10px;
	text-align: center;
	font-size: 18px;
	font-weight: bold;

`;

export const ModalContent = styled.div`
	font-size: 14px;
	text-align: center;
`;

export const StartButton = styled(PrimaryButton)`
	display: ${({isVisible}) => isVisible?"block":"none"};
	font-size: 18px;
`;