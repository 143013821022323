import {combineReducers} from 'redux';
import {all, fork} from 'redux-saga/effects';
import user, {userSaga} from './user';
// import profile, {profileSaga} from './profile';
// import jobs, {jobsSaga} from './jobs';
// import community, {communitySaga} from './community';
// import notifications, {notificationSaga} from './notifications';

export function* rootSaga() {
  yield all([
    userSaga()
  ])
}

export default combineReducers({
  user: user
})