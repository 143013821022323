import {useState, useEffect} from 'react';
import {
	RootContainer,
	VideoContainer,
	QuestionContainer,
	QuestionCard,
	QuestionTitle,
	QuestionTime,
} from './PreviousRecording.styles.js';
import {PrimaryButton, VerticalSpace} from 'components';
import { oembed } from "@loomhq/loom-embed";
import {userActions} from 'modules/user';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {useParams, useNavigate} from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import useQuery from 'utils/useQuery';

const Recording = () => {
	const {id} = useParams()
	const navigate = useNavigate()
	const [currentAnswerIndex, setCurrentAnswerIndex] = useState(0)
	const {allAnswers} = useSelector(state => state.user, shallowEqual)
	const [videoHTML, setVideoHTML] = useState("");

	useEffect(() => {
		if(!allAnswers || !allAnswers.length)
			navigate('/dashboard')

		const index = allAnswers.findIndex(item => item.videoId === id)
		setCurrentAnswerIndex(index)
	}, [allAnswers])

	useEffect(() => {
		const getLoomEmbed = async (videoId) => {
			// console.log("LOOM EMBED", ((window.innerWidth/4)*3))
			const { html } = await oembed(`https://www.loom.com/share/${videoId}`, {height: window.innerHeight});
        	setVideoHTML(html);
		}
		if(allAnswers.length) {
			getLoomEmbed(allAnswers[currentAnswerIndex]?.videoId)
		}
	}, [allAnswers, currentAnswerIndex])

	return (
		<RootContainer>
			<VideoContainer dangerouslySetInnerHTML={{ __html: videoHTML }} >
			</VideoContainer>
			<QuestionContainer>
				{
					allAnswers?.length
					?
					allAnswers[currentAnswerIndex]?.answers?.map(answer => (
						<QuestionCard>
							<QuestionTitle>Q{answer.questionNumber}: {answer.question}</QuestionTitle>
							<QuestionTime>{moment.duration(answer.time*1000).minutes()} min {moment.duration(answer.time*1000).seconds()} secs</QuestionTime>
						</QuestionCard>
					))
					:
					"No recording found..."
				}
				<VerticalSpace count={3} />
				{
					allAnswers[currentAnswerIndex]?.pptLink
					?
					<PrimaryButton background="blue" onClick={() => window.open(allAnswers[currentAnswerIndex]?.pptLink, '_blank')} >Get Transcript ⬇</PrimaryButton>
					:
					<PrimaryButton background="black" text="halfWhite" onClick={() => alert("We will notify you once the transcript is available!")} >Transcript (Work in progress..)</PrimaryButton>
				}
				<VerticalSpace count={3} />
			</QuestionContainer>
		</RootContainer>
	)
}

export default Recording;
