import {useState, useEffect} from 'react';
import {
	RootContainer,
	LogoImage,
	BodyContainer,
	WelcomeText,
	MainText,
	TaglineText,
	ArrowImage,
	StartButton,
	NoteText,
} from './Opening.styles';
import {HorizontalSpace, VerticalSpace, PrimaryButton} from 'components';
import Logo from 'assets/images/brainsprays-logo.webp';
import Arrow from 'assets/images/down-arrow-png-image.png';
import {detect} from 'detect-browser';
import SquareLoader from 'react-spinners/SquareLoader';
import {useNavigate} from 'react-router-dom';
import {userActions} from 'modules/user';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import useQuery from 'utils/useQuery';
import isMobile from 'ismobilejs'

import { useAuth0 } from "@auth0/auth0-react";

const Opening = () => {
	const dispatch = useDispatch();
	const query = useQuery()
	const navigate = useNavigate()
	const { loginWithRedirect, user, isAuthenticated, isLoading } = useAuth0();
	const [isSupported, setIsSupported] = useState(true)
	const apiLoading = useSelector(state => state.user.isLoading)

	useEffect(() => {
		const browser = detect()
		// console.log("IS MOBILE", isMobile(window.navigator))
		// console.log("isSupported", browser.name === "chrome", parseInt(browser.version) > 90)
		if(!(browser.name === "chrome" && parseInt(browser.version) > 90) || isMobile(window.navigator).any) {  //&& !(browser.name === "edge-chromium" && parseInt(browser.version) > 90)
			setIsSupported(false)
		}

		const zoomUserId = query.get('id')

		if(zoomUserId) {
			dispatch(userActions.getUserAsyncActions.request({data: zoomUserId, action: query.get('action'), callback: () => navigate('/interview')}))
		}

	}, [])

	useEffect(() => {
		// console.log("SUER LOADING", user, isLoading)
		if(user) {
			navigate('/dashboard')
		}
	}, [user, isLoading])

	if(isSupported && apiLoading && isMobile(window.navigator)) {
		return (
			<RootContainer>
				<BodyContainer>
					<SquareLoader size={35} color="#138DCA" />
				</BodyContainer>
			</RootContainer>
		)
	}

	return (
		<RootContainer>
			<VerticalSpace count={1} />
			<LogoImage src={Logo} />
			<BodyContainer>
				<WelcomeText>Welcome to Phonics by Brainsprays!</WelcomeText>
				<VerticalSpace count={2} />
				<MainText>Help Your Child Reach Their Phonics Goals</MainText>
				<TaglineText>Family Sing Along (Practice) & Sing in the Blank (Assess)</TaglineText>
				<ArrowImage src={Arrow} />
				{
					isSupported
					?
					isLoading
					?
					<SquareLoader size={35} color="#138DCA" />
					:
					<PrimaryButton onClick={() => loginWithRedirect()} >Start →</PrimaryButton>
					:
					<NoteText danger >We currently support desktop version of Google Chrome v90+ browsers only &#128578;. Sorry for the inconvinience!</NoteText>
				}
				<VerticalSpace count={1} />
				<NoteText>Requires LinkedIn authentication. By clicking start, you agree to <a href="https://prepair.ai/privacy" target="_blank">Privacy Policy</a> and the <a href="https://prepair.ai/terms-of-use" target="_blank">Terms and Conditions</a>.</NoteText>
			</BodyContainer>
		</RootContainer>
	)
}

export default Opening;